import axios, {AxiosResponse} from 'axios'
import {GameArt, GamePayload} from '../models/response/GamePayload'
import {CreateGamePayload, GameArtFile, UpdateGameArt} from '../models/payload/CreateGamePayload'
import coreClient from '../coreClient'
import { ID } from '../../../_metronic/helpers'

let axiosConfig = {
  headers: {
    accept: 'application/json',
  },
}

export function getGames(query?: string) { 

  let url = query ? `/Games?${query}` : `/Games?pageSize=100`

  return coreClient.get(url, {
    headers: {accept: 'application/json'},
  })
  // .then((d: AxiosResponse<UsersQueryResponse>) => d)
}

// export function getGamesById(id: number) {
//   return coreClient.get<GamePayload>(`/Games/${id}`, {
//     headers: {accept: 'application/json'},
//   })

export  const getGameById = (id: ID): Promise<any> => {
    return coreClient.get<any>(`/Games/${id}`, {
      headers: {accept: 'application/json'},
    })
}

export function postGames(payload: CreateGamePayload): Promise<AxiosResponse<any>> {
  return coreClient.post<GamePayload>(`/Games`, payload, axiosConfig)
}

export function postGamesById(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post<GamePayload>(
    `/Games/${id}/Types`,
    payload,
    axiosConfig
  )
}

export function postGamesByPlatform(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post<GamePayload>(
    `/Games/${id}/Platforms`,
    payload,
    axiosConfig
  )
}

export function postGamesByImage(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post(`/Games/${id}/Images`, payload, axiosConfig)
}

export function postTheScoreOption(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post<GamePayload>(
    `/Games/${id}/ScoreOptions`,
    payload,
    axiosConfig
  )
}

export function postArtSet(id: number, payload: GameArt): Promise<AxiosResponse<any>> {
  return coreClient.post<GameArt>(
    `/Games/${id}/Arts`,
    payload,
    axiosConfig
  )
}

export function addArtSetFile(
  id: number,
  artSetId: number,
  payload: GameArtFile
): Promise<AxiosResponse<any>> {
  return coreClient.post<GameArt>(
    `/Games/${id}/Arts/${artSetId}/Files`,
    payload,
    axiosConfig
  )
}

export function updateGames(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(`/Games/${id}`, payload, axiosConfig)
}

export function updateGameArt(
  id: number,
  artId: number,
  payload: UpdateGameArt
): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(
    `/Games/${id}/Arts/${artId}`,
    payload,
    axiosConfig
  )
}

export const deleteGame = (userId: ID): Promise<void> => {
  return coreClient.delete(`/Games/${userId}`).then(() => {})
}

export function deleteGameByTypeId(id: number, typeId: number): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(`/Games/${id}/Types/${typeId}`)
}

export function deleteGameByPlatformId(
  id: number,
  platformId: number
): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Games/${id}/Platforms/${platformId}`
  )
}
export function deleteGameByScoreOptinId(
  id: number,
  scoreOptionId: number
): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Games/${id}/ScoreOptions/${scoreOptionId}`
  )
}
export function deleteGameByImageId(id: number, imageId: number): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(`/Games/${id}/Images/${imageId}`)
}

export function deleteArtSetByImageId(id: number, imageId: number): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(`/Games/${id}/Images/${imageId}`)
}

export function deleteArtSet(id: number, artSetIndex: number): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(`/Games/${id}/Arts/${artSetIndex}`)
}

export function deleteArtSetFile(
  id: number,
  artSetIndex: number,
  fileIndex: number
): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Games/${id}/Arts/${artSetIndex}/Files/${fileIndex}`
  )
}
//not used
export const deleteSelectedGames = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => coreClient.delete(`/${id}`))
  return axios.all(requests).then(() => {})
}
